define("discourse/plugins/discourse-ai/discourse/components/ai-sentiment-horizontal-bar", ["exports", "@ember/helper", "@ember/template", "truth-helpers", "discourse-i18n", "float-kit/components/d-tooltip", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _template, _truthHelpers, _discourseI18n, _dTooltip, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AiSentimentHorizontalBar = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if (gt @score 0)}}
      <DTooltip
        class={{concat "sentiment-horizontal-bar__" @type}}
        style={{htmlSafe (concat "width: " @width "%")}}
      >
        <:trigger>
          <span class="sentiment-horizontal-bar__count">
            {{@score}}
          </span>
        </:trigger>
        <:content>
          {{i18n
            (concat
              "discourse_ai.sentiments.sentiment_analysis.filter_types." @type
            )
          }}:
          {{@score}}
        </:content>
      </DTooltip>
    {{/if}}
  
  */
  {
    "id": "RxLInASj",
    "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,1],0],null],[[[1,\"    \"],[8,[32,1],[[16,0,[28,[32,2],[\"sentiment-horizontal-bar__\",[30,2]],null]],[16,5,[28,[32,3],[[28,[32,2],[\"width: \",[30,3],\"%\"],null]],null]]],null,[[\"trigger\",\"content\"],[[[[1,\"\\n        \"],[10,1],[14,0,\"sentiment-horizontal-bar__count\"],[12],[1,\"\\n          \"],[1,[30,1]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[1,[28,[32,4],[[28,[32,2],[\"discourse_ai.sentiments.sentiment_analysis.filter_types.\",[30,2]],null]],null]],[1,\":\\n        \"],[1,[30,1]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@score\",\"@type\",\"@width\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-sentiment-horizontal-bar.js",
    "scope": () => [_truthHelpers.gt, _dTooltip.default, _helper.concat, _template.htmlSafe, _discourseI18n.i18n],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "ai-sentiment-horizontal-bar:AiSentimentHorizontalBar"));
  var _default = _exports.default = AiSentimentHorizontalBar;
});