define("discourse/plugins/discourse-ai/discourse/components/ai-llm-selector", ["exports", "@ember/helper", "select-kit/components/combo-box", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _comboBox, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AiLlmSelector = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <ComboBox
      @value={{@value}}
      @content={{@llms}}
      @onChange={{@onChange}}
      @options={{hash
        filterable=true
        none="discourse_ai.ai_persona.no_llm_selected"
      }}
      class={{@class}}
    />
  
  */
  {
    "id": "nJvrK5ml",
    "block": "[[[1,\"\\n  \"],[8,[32,0],[[16,0,[30,1]]],[[\"@value\",\"@content\",\"@onChange\",\"@options\"],[[30,2],[30,3],[30,4],[28,[32,1],null,[[\"filterable\",\"none\"],[true,\"discourse_ai.ai_persona.no_llm_selected\"]]]]],null],[1,\"\\n\"]],[\"@class\",\"@value\",\"@llms\",\"@onChange\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-llm-selector.js",
    "scope": () => [_comboBox.default, _helper.hash],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "ai-llm-selector:AiLlmSelector"));
  var _default = _exports.default = AiLlmSelector;
});