define("discourse/plugins/discourse-ai/discourse/connectors/full-page-search-below-search-header/ai-full-page-discobot-discoveries", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/d-icon", "discourse-i18n", "discourse/plugins/discourse-ai/discourse/components/ai-search-discoveries", "discourse/plugins/discourse-ai/discourse/components/ai-search-discoveries-tooltip", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _dIcon, _discourseI18n, _aiSearchDiscoveries, _aiSearchDiscoveriesTooltip, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiFullPageDiscobotDiscoveries extends _component.default {
    static shouldRender(_args, _ref) {
      let {
        siteSettings,
        currentUser
      } = _ref;
      return siteSettings.ai_bot_discover_persona && currentUser?.can_use_ai_bot_discover_persona && currentUser?.user_option?.ai_search_discoveries;
    }
    static #_ = (() => dt7948.g(this.prototype, "discobotDiscoveries", [_service.service]))();
    #discobotDiscoveries = (() => (dt7948.i(this, "discobotDiscoveries"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.discobotDiscoveries.showDiscoveryTitle}}
          <h3
            class="ai-search-discoveries__discoveries-title full-page-discoveries"
          >
            <span>
              {{icon "discobot"}}
              {{i18n "discourse_ai.discobot_discoveries.main_title"}}
            </span>
    
            <AiSearchDiscoveriesTooltip />
          </h3>
        {{/if}}
    
        <div class="full-page-discoveries">
          <AiSearchDiscoveries @searchTerm={{@outletArgs.search}} />
        </div>
      
    */
    {
      "id": "AdZsrjBv",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"discobotDiscoveries\",\"showDiscoveryTitle\"]],[[[1,\"      \"],[10,\"h3\"],[14,0,\"ai-search-discoveries__discoveries-title full-page-discoveries\"],[12],[1,\"\\n        \"],[10,1],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"discobot\"],null]],[1,\"\\n          \"],[1,[28,[32,1],[\"discourse_ai.discobot_discoveries.main_title\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[8,[32,2],null,null,null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,0,\"full-page-discoveries\"],[12],[1,\"\\n      \"],[8,[32,3],null,[[\"@searchTerm\"],[[30,1,[\"search\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/full-page-search-below-search-header/ai-full-page-discobot-discoveries.js",
      "scope": () => [_dIcon.default, _discourseI18n.i18n, _aiSearchDiscoveriesTooltip.default, _aiSearchDiscoveries.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiFullPageDiscobotDiscoveries;
});