define("discourse/plugins/discourse-ai/discourse/components/ai-search-discoveries", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/did-update", "@ember/render-modifiers/modifiers/will-destroy", "@ember/runloop", "@ember/service", "discourse/components/cook-text", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse/lib/ajax", "discourse/lib/decorators", "discourse/lib/plugin-api", "discourse-i18n", "discourse/plugins/discourse-ai/discourse/lib/smooth-streamer", "discourse/plugins/discourse-ai/discourse/components/ai-blinking-animation", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _didInsert, _didUpdate, _willDestroy, _runloop, _service, _cookText, _dButton, _concatClass, _ajax, _decorators, _pluginApi, _discourseI18n, _smoothStreamer3, _aiBlinkingAnimation, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DISCOVERY_TIMEOUT_MS = 10000;
  class AiSearchDiscoveries extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "search", [_service.service]))();
    #search = (() => (dt7948.i(this, "search"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "messageBus", [_service.service]))();
    #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "discobotDiscoveries", [_service.service]))();
    #discobotDiscoveries = (() => (dt7948.i(this, "discobotDiscoveries"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "hideDiscoveries", [_tracking.tracked], function () {
      return false;
    }))();
    #hideDiscoveries = (() => (dt7948.i(this, "hideDiscoveries"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "fullDiscoveryToggled", [_tracking.tracked], function () {
      return false;
    }))();
    #fullDiscoveryToggled = (() => (dt7948.i(this, "fullDiscoveryToggled"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "discoveryPreviewLength", [_tracking.tracked], function () {
      return this.args.discoveryPreviewLength || 150;
    }))();
    #discoveryPreviewLength = (() => (dt7948.i(this, "discoveryPreviewLength"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "smoothStreamer", [_tracking.tracked], function () {
      return new _smoothStreamer3.default(() => this.discobotDiscoveries.discovery, newValue => this.discobotDiscoveries.discovery = newValue);
    }))();
    #smoothStreamer = (() => (dt7948.i(this, "smoothStreamer"), void 0))();
    discoveryTimeout = null;
    constructor() {
      super(...arguments);
      this.appEvents.on("full-page-search:trigger-search", this, this.triggerDiscovery);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off("full-page-search:trigger-search", this, this.triggerDiscovery);
    }
    detectSearch() {
      if (this.query?.length === 0 && this.discobotDiscoveries.discovery?.length > 0) {
        this.discobotDiscoveries.resetDiscovery();
        this.smoothStreamer.resetStreaming();
      }
      (0, _pluginApi.withPluginApi)(api => {
        api.addSearchMenuOnKeyDownCallback((searchMenu, event) => {
          if (!searchMenu || this.discobotDiscoveries.loadingDiscoveries) {
            return;
          }
          if (this.discobotDiscoveries.lastQuery === this.query) {
            return false;
          }
          if (event.key === "Enter" && this.query) {
            this.triggerDiscovery();
          }
          return true;
        });
      });
    }
    static #_9 = (() => dt7948.n(this.prototype, "detectSearch", [_decorators.bind]))();
    async _updateDiscovery(update) {
      if (this.query === update.query) {
        if (this.discoveryTimeout) {
          (0, _runloop.cancel)(this.discoveryTimeout);
        }
        if (!this.discobotDiscoveries.discovery) {
          this.discobotDiscoveries.discovery = "";
        }
        this.discobotDiscoveries.modelUsed = update.model_used;
        this.discobotDiscoveries.loadingDiscoveries = false;
        this.smoothStreamer.updateResult(update, "ai_discover_reply");
      }
    }
    static #_10 = (() => dt7948.n(this.prototype, "_updateDiscovery", [_decorators.bind]))();
    unsubscribe() {
      this.messageBus.unsubscribe("/discourse-ai/ai-bot/discover", this._updateDiscovery);
    }
    static #_11 = (() => dt7948.n(this.prototype, "unsubscribe", [_decorators.bind]))();
    subscribe() {
      this.messageBus.subscribe("/discourse-ai/ai-bot/discover", this._updateDiscovery);
    }
    static #_12 = (() => dt7948.n(this.prototype, "subscribe", [_decorators.bind]))();
    get query() {
      return this.args?.searchTerm || this.search.activeGlobalSearchTerm;
    }
    get toggleLabel() {
      if (this.fullDiscoveryToggled) {
        return "discourse_ai.discobot_discoveries.collapse";
      } else {
        return "discourse_ai.discobot_discoveries.tell_me_more";
      }
    }
    get toggleIcon() {
      if (this.fullDiscoveryToggled) {
        return "chevron-up";
      } else {
        return "";
      }
    }
    get canShowExpandtoggle() {
      return !this.discobotDiscoveries.loadingDiscoveries && this.smoothStreamer.renderedText.length > this.discoveryPreviewLength;
    }
    get renderPreviewOnly() {
      return !this.fullDiscoveryToggled && this.canShowExpandtoggle;
    }
    async triggerDiscovery() {
      if (this.discobotDiscoveries.lastQuery === this.query) {
        this.hideDiscoveries = false;
        return;
      } else {
        this.smoothStreamer.resetStreaming();
        this.discobotDiscoveries.resetDiscovery();
      }
      this.hideDiscoveries = false;
      this.discobotDiscoveries.loadingDiscoveries = true;
      this.discoveryTimeout = (0, _runloop.later)(this, this.timeoutDiscovery, DISCOVERY_TIMEOUT_MS);
      try {
        this.discobotDiscoveries.lastQuery = this.query;
        await (0, _ajax.ajax)("/discourse-ai/ai-bot/discover", {
          data: {
            query: this.query
          }
        });
      } catch {
        this.hideDiscoveries = true;
      }
    }
    static #_13 = (() => dt7948.n(this.prototype, "triggerDiscovery", [_object.action]))();
    toggleDiscovery() {
      this.fullDiscoveryToggled = !this.fullDiscoveryToggled;
    }
    static #_14 = (() => dt7948.n(this.prototype, "toggleDiscovery", [_object.action]))();
    timeoutDiscovery() {
      this.discobotDiscoveries.loadingDiscoveries = false;
      this.discobotDiscoveries.discovery = "";
      this.discobotDiscoveries.discoveryTimedOut = true;
    }
    static #_15 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class="ai-search-discoveries"
          {{didInsert this.subscribe this.query}}
          {{didUpdate this.subscribe this.query}}
          {{didUpdate this.detectSearch this.query}}
          {{didInsert this.triggerDiscovery this.query}}
          {{willDestroy this.unsubscribe}}
        >
          <div class="ai-search-discoveries__completion">
            {{#if this.discobotDiscoveries.loadingDiscoveries}}
              <AiBlinkingAnimation />
            {{else if this.discobotDiscoveries.discoveryTimedOut}}
              {{i18n "discourse_ai.discobot_discoveries.timed_out"}}
            {{else}}
              <article
                class={{concatClass
                  "ai-search-discoveries__discovery"
                  (if this.renderPreviewOnly "preview")
                  (if this.smoothStreamer.isStreaming "streaming")
                  "streamable-content"
                }}
              >
                <CookText
                  @rawText={{this.smoothStreamer.renderedText}}
                  class="cooked"
                />
              </article>
    
              {{#if this.canShowExpandtoggle}}
                <DButton
                  class="btn-flat btn-text ai-search-discoveries__toggle"
                  @label={{this.toggleLabel}}
                  @icon={{this.toggleIcon}}
                  @action={{this.toggleDiscovery}}
                />
              {{/if}}
            {{/if}}
          </div>
        </div>
      
    */
    {
      "id": "N1ehARH5",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"ai-search-discoveries\"],[4,[32,0],[[30,0,[\"subscribe\"]],[30,0,[\"query\"]]],null],[4,[32,1],[[30,0,[\"subscribe\"]],[30,0,[\"query\"]]],null],[4,[32,1],[[30,0,[\"detectSearch\"]],[30,0,[\"query\"]]],null],[4,[32,0],[[30,0,[\"triggerDiscovery\"]],[30,0,[\"query\"]]],null],[4,[32,2],[[30,0,[\"unsubscribe\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"ai-search-discoveries__completion\"],[12],[1,\"\\n\"],[41,[30,0,[\"discobotDiscoveries\",\"loadingDiscoveries\"]],[[[1,\"          \"],[8,[32,3],null,null,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"discobotDiscoveries\",\"discoveryTimedOut\"]],[[[1,\"          \"],[1,[28,[32,4],[\"discourse_ai.discobot_discoveries.timed_out\"],null]],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,\"article\"],[15,0,[28,[32,5],[\"ai-search-discoveries__discovery\",[52,[30,0,[\"renderPreviewOnly\"]],\"preview\"],[52,[30,0,[\"smoothStreamer\",\"isStreaming\"]],\"streaming\"],\"streamable-content\"],null]],[12],[1,\"\\n            \"],[8,[32,6],[[24,0,\"cooked\"]],[[\"@rawText\"],[[30,0,[\"smoothStreamer\",\"renderedText\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"canShowExpandtoggle\"]],[[[1,\"            \"],[8,[32,7],[[24,0,\"btn-flat btn-text ai-search-discoveries__toggle\"]],[[\"@label\",\"@icon\",\"@action\"],[[30,0,[\"toggleLabel\"]],[30,0,[\"toggleIcon\"]],[30,0,[\"toggleDiscovery\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"        \"]],[]]]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-search-discoveries.js",
      "scope": () => [_didInsert.default, _didUpdate.default, _willDestroy.default, _aiBlinkingAnimation.default, _discourseI18n.i18n, _concatClass.default, _cookText.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiSearchDiscoveries;
});