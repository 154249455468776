define("discourse/plugins/discourse-ai/discourse/components/ai-tool-selector", ["exports", "@ember/helper", "select-kit/components/multi-select", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _multiSelect, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AiToolSelector = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <MultiSelect
      @value={{@value}}
      @onChange={{@onChange}}
      @content={{@content}}
      @options={{hash filterable=true allowAny=false disabled=@disabled}}
    />
  
  */
  {
    "id": "gsEPc7NG",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@value\",\"@onChange\",\"@content\",\"@options\"],[[30,1],[30,2],[30,3],[28,[32,1],null,[[\"filterable\",\"allowAny\",\"disabled\"],[true,false,[30,4]]]]]],null],[1,\"\\n\"]],[\"@value\",\"@onChange\",\"@content\",\"@disabled\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-tool-selector.js",
    "scope": () => [_multiSelect.default, _helper.hash],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "ai-tool-selector:AiToolSelector"));
  var _default = _exports.default = AiToolSelector;
});