define("discourse/plugins/discourse-ai/discourse/services/discobot-discoveries", ["exports", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscobotDiscoveries extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))(); // We use this to retain state after search menu gets closed.
    // Similar to discourse/discourse#25504
    static #_2 = (() => dt7948.g(this.prototype, "discovery", [_tracking.tracked], function () {
      return "";
    }))();
    #discovery = (() => (dt7948.i(this, "discovery"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "lastQuery", [_tracking.tracked], function () {
      return "";
    }))();
    #lastQuery = (() => (dt7948.i(this, "lastQuery"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "discoveryTimedOut", [_tracking.tracked], function () {
      return false;
    }))();
    #discoveryTimedOut = (() => (dt7948.i(this, "discoveryTimedOut"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "modelUsed", [_tracking.tracked], function () {
      return "";
    }))();
    #modelUsed = (() => (dt7948.i(this, "modelUsed"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "loadingDiscoveries", [_tracking.tracked], function () {
      return false;
    }))();
    #loadingDiscoveries = (() => (dt7948.i(this, "loadingDiscoveries"), void 0))();
    resetDiscovery() {
      this.loadingDiscoveries = false;
      this.discovery = "";
      this.discoveryTimedOut = false;
      this.modelUsed = "";
    }
    get showDiscoveryTitle() {
      return this.discovery.length > 0 || this.loadingDiscoveries;
    }
    async disableDiscoveries() {
      this.currentUser.user_option.ai_search_discoveries = false;
      await this.currentUser.save(["ai_search_discoveries"]);
      location.reload();
    }
    static #_7 = (() => dt7948.n(this.prototype, "disableDiscoveries", [_object.action]))();
  }
  _exports.default = DiscobotDiscoveries;
});